var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    {
      staticClass: "start-button-mission",
      attrs: { column: "", "fill-height": "" },
    },
    [
      _c(
        "v-layout",
        {
          staticClass: "start-button-mission__instructions",
          attrs: {
            column: "",
            "justify-space-between": "",
            "align-center": "",
          },
        },
        [
          _c("img", {
            staticClass: "mt-3",
            staticStyle: { width: "300px" },
            attrs: { src: require("@/assets/start-button-logo.png") },
          }),
          _c(
            "v-flex",
            {
              staticClass: "d-flex align-center justify-center pb-4",
              staticStyle: { "font-size": "32px" },
              attrs: { grow: "" },
            },
            [_vm._v(" " + _vm._s(_vm.game.externalName || _vm.game.name) + " ")]
          ),
          !_vm.isEnoughPlayers
            ? _c(
                "v-flex",
                {
                  staticClass: "d-flex align-center justify-center pb-4 grow",
                  staticStyle: { "font-size": "23px" },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        "You need " +
                          _vm.nOfPlayersNeeded +
                          " more player(s) to start the game."
                      ) +
                      " "
                  ),
                ]
              )
            : _vm._e(),
          _c(
            "v-tooltip",
            {
              attrs: { bottom: "" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function (ref) {
                    var on = ref.on
                    return [
                      _c(
                        "RtbButton",
                        _vm._g(
                          {
                            staticClass: "start-button-mission__button",
                            attrs: {
                              disabled: !_vm.isStartButtonEnabled,
                              size: "xl",
                            },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.handleClick()
                              },
                            },
                          },
                          on
                        ),
                        [_vm._v(" Start Game ")]
                      ),
                    ]
                  },
                },
              ]),
            },
            [
              _c("span", { staticClass: "bottom" }, [
                _vm._v(" This will also enable auto-pilot "),
              ]),
            ]
          ),
          _vm.isDemoGame
            ? _c("div", { staticClass: "start-button-mission__tip" }, [
                _vm._v(" Click start game when everyone has arrived "),
              ])
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }