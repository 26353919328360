<template>
  <v-layout column fill-height class="start-button-mission">
    <!-- Mission question -->
    <v-layout
      column
      justify-space-between
      align-center
      class="start-button-mission__instructions"
    >
      <img
        class="mt-3"
        style="width: 300px"
        :src="require('@/assets/start-button-logo.png')"
      />

      <v-flex
        grow
        class="d-flex align-center justify-center pb-4"
        style="font-size: 32px"
      >
        {{ game.externalName || game.name }}
      </v-flex>

      <v-flex
        class="d-flex align-center justify-center pb-4 grow"
        style="font-size: 23px"
        v-if="!isEnoughPlayers"
      >
        {{ `You need ${nOfPlayersNeeded} more player(s) to start the game.` }}
      </v-flex>

      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <RtbButton
            v-on="on"
            @click.stop="handleClick()"
            :disabled="!isStartButtonEnabled"
            class="start-button-mission__button"
            size="xl"
          >
            Start Game
          </RtbButton>
        </template>

        <span class="bottom"> This will also enable auto-pilot </span>
      </v-tooltip>
      <div class="start-button-mission__tip" v-if="isDemoGame">
        Click start game when everyone has arrived
      </div>
    </v-layout>
  </v-layout>
</template>

<script>
import { mapGetters } from "vuex"
import { db } from "@/firebase"

import { Mission, Role } from "@shared/enums"
import NavigationService from "@/services/navigation.service"
import User from "@shared/User"
import {
  NotificationMixin,
  NotificationType,
  NotificationScope
} from "@/mixins/NotificationMixin"
import { RtbButton } from "@/components/ui"

export default {
  name: "StartButton",
  mixins: [NotificationMixin],
  components: { RtbButton },
  props: {
    mission: Object
  },
  computed: {
    ...mapGetters({ gameID: "actualGameID" }),
    ...mapGetters(["orgID", "isAutopilotOn", "game"]),
    ...mapGetters("auth", ["user", "isHost", "isModerator", "client"]),
    ...mapGetters("GameUsers", ["usersOnlineArray"]),
    missionType() {
      return this?.mission?.behavior
    },
    isOpenWeveStart() {
      return this.missionType === Mission.OpenWeveStart
    },
    nOfPlayersRequired() {
      return parseInt(this.mission?.nOfPlayersRequired) || 0
    },
    nOfPlayersNeeded() {
      const n = this.nOfPlayersRequired - this.nOfPlayersInGame
      return n > 0 ? n : 0
    },
    isStartButtonEnabled() {
      return this.isEnoughPlayers && !this.isAutopilotOn
    },
    isEnoughPlayers() {
      return this.nOfPlayersInGame >= this.nOfPlayersRequired
    },
    nOfPlayersInGame() {
      return this.usersOnlineArray.filter(
        user => User.isPlayer(user) && User.isPlaying(user)
      ).length
    },
    isOnlyOnePlayer() {
      return this.nOfPlayersInGame < 2
    },
    isDemoGame() {
      return Boolean(this.client?.demo)
    }
  },
  mounted() {
    if (this.isHost && !this.isModerator && this.isOpenWeveStart) {
      NavigationService.next(this.orgID, this.gameID)
    }
  },
  methods: {
    handleClick() {
      if (this.isOpenWeveStart && this.isOnlyOnePlayer) {
        this.confirmStart()
      } else {
        this.onStart()
      }
    },
    async confirmStart() {
      try {
        !this.isDemoGame &&
          (await this.$confirm({
            title:
              "Are you sure you would like to start? You’re the only one here."
          }))

        await this.goNext()
      } catch (e) {}
    },
    async onStart() {
      try {
        !this.isDemoGame &&
          (await this.$confirm({
            message: `Make sure everyone's here before you begin! Are you sure you want to start the game?`
          }))

        await this.goNext()
      } catch (e) {
        console.error(e)
      }
    },
    async goNext() {
      await db
        .auxiliary()
        .ref(`/org/${this.orgID}/games/${this.gameID}`)
        .transaction(object => {
          const value = object || {}
          if (value.autopilot && value.started) return
          object.started = true
          object.autopilot = true
          return object
        })

      if (this.user?.role === Role.PLAYER) {
        await this.$_NotificationMixin_send({
          type: NotificationType.START_GAME,
          scope: NotificationScope.GLOBAL
        })
      }
    }
  }
}
</script>

<style lang="scss">
.start-button-mission {
  &__image-asset-container {
    position: relative;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    transform: translateZ(0);
  }

  &__button {
    width: 400px;
    text-transform: capitalize;
    font-size: 28px;
    padding-bottom: 5px;
  }

  &__instructions {
    padding: 16px;
  }

  &__tip {
    color: #b6d6ff;
    font-size: 12px;
    text-align: center;
    padding-top: 8px;
  }
}
</style>
